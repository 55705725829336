import React from 'react'
import { css } from '@emotion/core'
import { Container } from 'react-bootstrap'
/* eslint no-unused-vars: 0 */
import style from './styles.css'

export default () => (
  <div className="service">
    <Container
      css={css`
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
        border-radius: 27px;
        font-family: PingFangSC-Regular, PingFang SC;
      `}
    >
      <div className="service-content">
        <div
          css={css`
            font-weight: bold;
            text-align: center;
            margin: 0 auto;
          `}
          className="service-content-title"
        >
          欢迎使用无锋网络银行账户实名认证API网关 （WF Cloud API Gateway）服务
          <br />
          <br />
        </div>
        <div className="service-content-text">
          在开通WF Cloud API
          Gateway网关服务前，请您仔细阅读本服务协议的全部内容，如果您不同意前述任意内容，或者无法准确理解，请不要进行后续操作。如您开通或实际使用了无锋网络银行账户实名认证API网关服务，无锋网络将视为您已完全理解并认同规范、规则、流程及服务协议的全部内容，届时您不应以未阅读、未理解或者未获得无锋对您问询的解答等理由，主张本服务协议无效，或要求撤销本服务协议。
          关于本服务协议，提示您特别关注限制、免责条款，无锋网络对您违规、违约行为的认定处理条款，以及管辖法院的选择条款等。限制、免责条款可能以加粗或加下划线形式提示您注意。
          <br /> <br />
        </div>
        <div
          css={css`
            font-weight: bold;
          `}
          className="service-content-subhead"
        >
          1.签约主体
        </div>
        <div className="service-content-text">
          本服务协议是您因使用银行账户实名认证API网关服务与福州无锋网络有限责任公司所订立的有效合约。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-weight: bold;
          `}
          className="service-content-subhead"
        >
          2. 协议的订立和生效
        </div>
        <div className="service-content-text">
          一旦您选择“开通服务”并进行后续操作，即表示您同意遵循本服务协议之所有约定，本服务协议即成为双方之间就WF
          Cloud API Gateway网关服务所达成的有效合约。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-weight: bold;
          `}
          className="service-content-subhead"
        >
          3.WF Cloud API Gateway网关服务的使用
        </div>
        <div>
          <div className="service-content-text">
            3.1. 在使用WF Cloud API
            Gateway网关服务前，您应仔细阅读无锋提供的相关服务说明、技术规范、使用流程，并理解相关内容及可能发生的后果，在使用WF
            Cloud API
            Gateway网关服务过程中，您应依照相关操作指引进行操作，请您自行把握风险谨慎操作。
            <br />
            <br />
          </div>
          <div className="service-content-text">
            3.2. 您理解并同意，使用WF Cloud API
            Gateway服务是您自行独立审慎判断的结果，您将自行对此负责，包括但不限于:
            <br />
            <br />
          </div>
          <div className="service-content-text">
            3.2.1.在使用WF Cloud API
            Gateway服务过程中，您需要对API定义/API流控/API访问权限自行操作，您将对自行操作的行为及其产生的结果负责；
            <br />
            <br />
          </div>
          <div className="service-content-text">
            3.3. 您理解并认可，您在使用WF Cloud API Gateway服务过程中： <br />
            <br />
          </div>
          <div className="service-content-text">
            3.3.1.
            您不应进行任何破坏或试图破坏网络安全的行为（包括但不限于钓鱼，黑客，网络诈骗，API中含有或涉嫌散播：病毒、木马、恶意代码，及通过虚拟服务器对其他网站、服务器进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DDOS等）；
            <br />
            <br />
          </div>
          <div className="service-content-text">
            3.3.2. 您不应进行任何改变或试图改变提供WF Cloud API
            Gateway的系统配置或破坏系统安全及网络安全的行为； <br />
            <br />
          </div>
          <div className="service-content-text">
            3.3.3. 不得修改、翻译、改编、出租、转许可、在信息网络上传播或转让WF
            Cloud API
            Gateway提供的服务或软件，也不得逆向工程、反编译或试图以其他方式发现无锋提供的服务或软件的源代码；
            <br />
            <br />
          </div>
          <div className="service-content-text">
            3.3.4.
            您不应以任何将会违反国家、地方法律法规、行业惯例和社会公共道德，及影响、损害或可能影响、损害无锋利益的方式或目的使用WF
            Cloud API Gateway服务。
            <br />
            <br />
          </div>
        </div>
        <div
          css={css`
            font-weight: bold;
          `}
          className="service-content-subhead"
        >
          4.责任限制
        </div>
        <div className="service-content-text">
          您理解并同意，无锋网络虽然对WF Cloud API
          Gateway服务提供可用性支撑，但不对其中任何错误或漏洞提供任何担保，并不对您使用WF
          Cloud API Gateway服务的工作或结果承担任何责任。 <br />
          <br />
        </div>
        <div
          css={css`
            font-weight: bold;
          `}
          className="service-content-subhead"
        >
          5.变更和终止
        </div>
        <div>
          <div className="service-content-text">
            5.1. 您理解并认可，无锋网络保留随时修改、取消、增强WF Cloud API
            Gateway服务一项或多项功能的权利，并有权要求您使用最新更新的版本；届时，无锋网络将以提前通过在网站内合适版面发布公告或发送站内通知等方式通知您。
            <br /> <br />
          </div>
          <div className="service-content-text">
            5.2.
            如您有任何违反本服务协议的情形，或经无锋根据自己的独立判断认为您对WF
            Cloud API
            Gateway的使用行为不符合无锋的要求，无锋除有权随时中断或终止您使用WF
            Cloud API
            Gateway服务而无须通知您；同时，如给无锋网络造成损失的，无锋网络有权要求您赔偿损失。
            <br /> <br />
          </div>
        </div>
        <div
          css={css`
            font-weight: bold;
          `}
          className="service-content-subhead"
        >
          6.保密
        </div>
        <div className="service-content-text">
          您及无锋网络都应对对方的保密信息承担保密责任，除非经国家行政、司法等有权机关要求披露或该信息已进入公有领域。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-weight: bold;
          `}
          className="service-content-subhead"
        >
          7. 其他
        </div>
        <div className="service-content-text">
          7.1. 您理解并同意，无锋网络在测试期间为您提供免费的WF Cloud API
          Gateway服务，即您开通或使用WF Cloud API
          Gateway服务，并不需向无锋网络支付费用；测试期结束后无锋网络将收取费用，届时无锋网络将提前10个自然日公布收费政策及规范；如您仍使用WF
          Cloud API
          Gateway服务的，应按届时有效的收费政策及规范付费并应遵守届时无锋网络公布的有效的服务协议。
          <br /> <br />
          7.2.
          无锋网络有权随时根据有关法律、法规的变化以及公司经营状况和经营策略的调整等修改本服务协议。修改后的服务协议会在无锋网络网站上公布。如果不同意修改的内容，您应停止使用WF
          Cloud API Gateway服务。如果继续使用WF Cloud API
          Gateway服务，则视为您接受本服务协议的变动。 <br /> <br />
          7.3.
          如果本服务协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本服务协议的其余条款仍应有效并且有约束力。
          <br /> <br />
          7.4.
          本服务协议受中华人民共和国法律管辖。在执行本服务协议过程中如发生纠纷，双方应及时协商解决。协商不成时，任何一方可直接向福州市人民法院提起诉讼。
        </div>
      </div>
    </Container>
  </div>
)
